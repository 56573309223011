import React from 'react';
import 'antd/dist/antd.css';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { Suspense } from 'react';
import Wapepers from './wapepers';
export const history = createBrowserHistory();
export default function App() { 
  return (
    <>
      <Router history={history}>
        <Suspense fallback={null}>
          <Wapepers />
        </Suspense>
      </Router>
    </>
  );
}

import React,{ lazy, Suspense } from "react";
import SideNav from "../components/sidNav";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
const CustomerDocument = lazy(() => import('../../src/pages/CustomerDocument'));
const ProfileDocument = lazy(() => import('../../src/pages/ProfileDocument'));
const ProjectDocument = lazy(() => import('../../src/pages/ProjectDocument'));
const ScheduleDocument = lazy(() => import('../../src/pages/ScheduleDocument'));

export default function Wapepers() {
    return(
    <div>
      <BrowserRouter>
      <SideNav/>
        <Layout>
          <Layout>
            <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}> 
              <Suspense fallback={null}>
                <Switch>
                  <Route path="/" exact component={ProjectDocument} />
                  <Route path="/customer" exact component={CustomerDocument} />
                  <Route path="/profile" exact component={ProfileDocument} />
                  <Route path="/project" exact component={ProjectDocument} />
                  <Route path="/schedule" exact component={ScheduleDocument} />
                </Switch>
              </Suspense>
            </Content>
          </Layout>
        </Layout>
      </BrowserRouter>
    </div>
 
  )
}
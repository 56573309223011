import { Layout, Menu,   } from 'antd';
import styles from './styles.module.scss'
import { Link,useHistory } from 'react-router-dom';
import Icon from '@ant-design/icons/lib/components/Icon';
import { useState } from 'react';
import Icons from '../../common/icon';
const { Sider } = Layout;

export default function SideNav () {
  const history = useHistory();
  const [tabActived, setTabActived] = useState('1');
  const routes = [
    {
      key: '1',
      text: "顧客管理",
      url: '/customer',
    },
    {
      key: '2',
      text: "案件管理",
      url: '/project',
    },
    {
      key: '3',
      text: "プロフィール",
      url: '/profile',
    },
    {
      key: '4',
      text: "スケジュール",
      url: '/schedule',
    },
  ]
  const handleClickSideNav = (e) => {
    switch (e.key) {
      case '1':
        history.push('/customer');
        break;
      case '2':
        history.push('/project');
        break;
      case '3':
        history.push('/profile');
        break;
      case '4':
        history.push('/schedule');
        break;
      default:
        history.push('/');
        break;
    }
  }
  return(
    <>
      <Sider 
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}>

        <Menu
          mode="inline"
          defaultOpenKeys={[]}
          style={{ height: '100%' }}
          theme="dark" defaultSelectedKeys={['4']} 
        >
          {routes.map((route) => {
            return (
            <Menu.Item key={route.key}  onClick={(e) => handleClickSideNav(e)}>
              <Link to={route.url}>{route.text}</Link>
            </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
      
    </>
)
}
